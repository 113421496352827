<template>
  <div>
    <div class="mb-8 flex justify-between">
      <div>
        <div>
          <router-link :to="{name: 'Activity', params: {bookingId: booking.id}}" class="text-pink-500 hover:underline text-sm flex items-center">
            <ChevronLeftIcon class="h-3 w-3" />
            <span class="ml-2">Back to Activity Feed</span>
          </router-link>
        </div>
        <h2 class="mt-2 text-blue-800 font-bold text-3xl leading-none">Questions</h2>
      </div>

      <div v-if="userIsInvitee && booking.status == 'accepted'">
        <ConfirmDialog
          @confirm="markAsComplete"
          confirm-action="Yes, Complete"
          dialog-title="Mark As Complete?"
          dialog-text="You'll no longer be able to edit your answers after this step."
          class="bg-indigo-gradiant text-white text-sm font-bold py-2 px-6 rounded-full hover:opacity-90 disabled:opacity-50"
          :disabled="! miniviewIsComplete"
        >
          Mark as Complete
        </ConfirmDialog>
      </div>
    </div>

    <div v-if="userIsInvitee && booking.status == 'accepted' && remainingRequiredAnswers > 0" class="mb-8 bg-orange-100 border border-orange-400 rounded-lg shadow-lg text-orange-700 px-6 py-4 max-w-2xl w-full">
      <template v-if="hasAdditionalQuestions">
        You need to answer to at least {{ requiredAnswersCount }} questions ({{ remainingRequiredAnswers }} remaining).
      </template>
      <template v-else>
        You need to answer to all the questions ({{ remainingRequiredAnswers }} remaining).
      </template>
    </div>

    <div v-if="! userIsInvitee && booking.status == 'accepted'" class="mb-8 bg-orange-100 border border-orange-400 rounded-lg shadow-lg text-orange-700 px-6 py-4 max-w-2xl w-full">
      The guest is still working on the answers.
    </div>

    <div>
      <div v-if="! booking.miniviewQuestions.length" class="dashboard-card border border-gray-50">
        <div class="my-12 max-w-sm w-full mx-auto flex items-center justify-center text-center">
          <span class=" text-xl font-semibold">This MINIVIEW™ doesn't have any questions yet.</span>
        </div>
      </div>

      <div v-else class="space-y-8">
        <div v-for="(miniview, index) in miniviewQuestions" :key="`miniview-${index}`" class="border border-gray-50 max-w-2xl w-full shadow-lg rounded-xl bg-white">
          <div class="px-8 py-4">
            <h4 class="text-blue-800 font-bold text-lg">
              {{ index + 1 }}. {{ miniview.body }}
            </h4>
          </div>

          <div v-if="miniview.question_media_url" class="px-8 mb-8">
            <VideoPreview v-if="miniview.question_media_type === 'video_recordings'" :media-src="miniview.question_media_url" />
            <AudioPreview v-else-if="miniview.question_media_type === 'audio_recordings'" :media-src="miniview.question_media_url" />
            <a v-if="booking.status == 'completed' && !userIsInvitee" :href="downloadUrl + miniview.question_media_uuid" title="AnswerName" class="text-pink-500 font-semibold">{{downloadText}}</a>
          </div>

          <div v-if="miniview.answer_media_url" class="pt-4 pb-8 px-8 border-t border-guestio-gray-300">
            <div class="flex items-center justify-between">
              <h4 class="text-blue-800 font-bold">
                {{ userIsInvitee ? 'Your Answer' : 'Guest Answer' }}
              </h4>
              <div v-if="userIsInvitee && booking.status == 'accepted'">
                <modal ref="previewModal" :with-close-button="false" class="text-pink-500 space-x-2 flex items-center text-sm">
                  <EditAltIcon class="h-5 w-5" />
                  <span>Edit</span>

                  <template #content="{ close }">
                    <div class="max-w-xl w-full bg-white rounded-xl overflow-auto my-4 p-6 lg:px-8 max-h-screen-10">
                      <RecordVideoAnswer
                        v-if="miniview.answer_media_type === 'video_recordings'"
                        @close="close"
                        :miniview="miniview"
                        @update-miniview="updateMiniview(index, $event)"
                      />
                      <RecordAudioAnswer
                        v-else-if="miniview.answer_media_type === 'audio_recordings'"
                        @close="close"
                        :miniview="miniview"
                        @update-miniview="updateMiniview(index, $event)"
                      />
                    </div>
                  </template>
                </modal>
              </div>
            </div>
            <div class="mt-4">
              <VideoPreview v-if="miniview.answer_media_type === 'video_recordings'" :media-src="miniview.answer_media_url" />
              <AudioPreview v-else-if="miniview.answer_media_type === 'audio_recordings'" :media-src="miniview.answer_media_url" />
              <a v-if="booking.status == 'completed' && !userIsInvitee" :href="downloadUrl + miniview.answer_media_uuid" title="AnswerName" class="text-pink-500 font-semibold">{{downloadText}}</a>
            </div>
          </div>

          <div v-else>
            <div v-if="! userIsInvitee" class="px-8 pb-8">
              <div class="h-12 mb-2 flex justify-center items-center text-sm text-gray-500 bg-gray-50 rounded-lg border-2 border-dashed opacity-75">
                No answer added.
              </div>
            </div>
            <div v-else-if="userIsInvitee && booking.status == 'accepted'" class="mt-4 px-8 pb-8">
              <modal v-if="booking.booking_option_variation == 'video'" :with-close-button="false" class="bg-pink-500 text-sm text-blue-800 bg-opacity-10 flex items-center px-6 py-3 rounded-full hover:bg-opacity-20 focus:shadow-outline">
                <VideoIcon class="text-pink-500 h-4" />
                <span class="ml-3">Record Your Answer</span>

                <template #content="{ close }">
                  <div class="max-w-xl w-full bg-white rounded-xl overflow-auto my-4 p-6 lg:px-8 max-h-screen-10">
                    <RecordVideoAnswer @close="close" :miniview="miniview" @update-miniview="updateMiniview(index, $event)" />
                  </div>
                </template>
              </modal>

              <modal v-if="booking.booking_option_variation == 'audio'" :with-close-button="false" class="bg-pink-500 text-sm text-blue-800 bg-opacity-10 flex items-center px-6 py-3 rounded-full hover:bg-opacity-20 focus:shadow-outline">
                <MicIcon class="text-pink-500 h-4" />
                <span class="ml-3">Record Your Answer</span>

                <template #content="{ close }">
                  <div class="max-w-xl w-full bg-white rounded-xl overflow-auto my-4 p-6 lg:px-8 max-h-screen-10">
                    <RecordAudioAnswer @close="close" :miniview="miniview" @update-miniview="updateMiniview(index, $event)" />
                  </div>
                </template>
              </modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { get } from 'lodash'
  import api from '@/api'
  import ConfirmDialog from '@/components/shared/ConfirmDialog'
  import EditAltIcon from '@/components/svgs/EditAltIcon'
  import VideoIcon from '@/components/svgs/VideoIcon'
  import MicIcon from '@/components/svgs/MicIcon'
  import ChevronLeftIcon from '@/components/svgs/ChevronLeftIcon'
  import VideoPreview from '@/components/shared/VideoPreview'
  import AudioPreview from '@/components/shared/AudioPreview'
  import Modal from '@/components/shared/Modal'
  import RecordVideoAnswer from '@/components/bookings/individual/RecordVideoAnswer'
  import RecordAudioAnswer from '@/components/bookings/individual/RecordAudioAnswer'

  export default {
    name: "MiniviewCard",

    components: {
      ConfirmDialog,
      EditAltIcon,
      VideoIcon,
      MicIcon,
      ChevronLeftIcon,
      VideoPreview,
      AudioPreview,
      Modal,
      RecordVideoAnswer,
      RecordAudioAnswer,
    },

    data() {
      return {
        downloadText: 'Download'
      }
    },

    computed: {
      miniviewQuestions() {
        return get(this.booking, 'miniviewQuestions', [])
      },

      booking() {
        return this.$store.getters['dashboard/bookings/booking']
      },

      requiredAnswersCount() {
        return parseInt(this.booking.booking_option_amount)
      },

      remainingRequiredAnswers() {
        return this.requiredAnswersCount - this.answeredQuestions.length
      },

      userIsInvitee() {
        return this.$store.getters['dashboard/bookings/userIsInvitee']
      },

      answeredQuestions() {
        return this.miniviewQuestions.filter(miniview => !! miniview.answer_media_url)
      },

      hasAdditionalQuestions() {
        return this.miniviewQuestions.length - parseInt(this.booking.booking_option_amount) > 0
      },

      miniviewIsComplete() {
        if (this.answeredQuestions.length >= this.booking.booking_option_amount) {
          return true
        }

        return false
      },

      downloadUrl() {
        return process.env.VUE_APP_API_ENDPOINT + '/download/media/'
      }
    },

    methods: {
      updateMiniview(index, media) {
        let originalMiniview = this.miniviewQuestions[index]

        this.$set(originalMiniview, 'answer_media_url', media.url)
        this.$set(originalMiniview, 'answer_media_type', media.collection_name)

        api.put(`/bookings/${this.booking.id}/miniviews/${originalMiniview.id}`, {
          answer_media_id: media.id
        }).catch(() => {
          this.$toast.error('Error! The answer was not saved.')
        })
      },

      markAsComplete(callback) {
        api
          .patch(`/bookings/${this.booking.id}/complete`)
          .then(() => {
            window.location.href = `/bookings/${this.booking.id}`
          })
          .catch(() => {
            this.$toast.error('Error. The MINIVIEW™ was not marked as completed.')
            callback()
          })
      },
    },
  }
</script>
